import { useCallback, useState } from 'react';
import 'styles/badge.css';

export const useNewBadge = (featureName) => {
  const featureKey = `new_feature_${featureName}_visited`;
  const [visited, setVisited] = useState(Boolean(localStorage.getItem(featureKey)));
  const newBadgeClassName = visited ? '' : 'new-feature-badge';

  const onVisit = useCallback(() => {
    setVisited(true);
    localStorage.setItem(featureKey, true);
  }, [featureKey]);

  return [newBadgeClassName, onVisit];
};
